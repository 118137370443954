import { redirect } from 'react-router-dom';
import { getActiveManager } from '@lib/session';

async function guestLoader() {
  const manager = getActiveManager();
  const authenticated = await manager.authenticated();
  console.log('[LOADER] Public loader', { authenticated });

  if (authenticated) {
    console.log(
      "[LOADER] Trying to use a public route but the current session isn't empty.",
      'Redirecting to the /home page',
    );
    return redirect('/home');
  }

  return null;
}

export default guestLoader;
