import { TokenSessionManager, Storage } from '@lib/http';
import { WindowLocalStorage, getAvailableStorage } from '@lib/storage';

export type OptionalSessionManager = TokenSessionManager | null;

let manager: OptionalSessionManager = null;

export const setManager = (newOne: OptionalSessionManager) => {
  manager = newOne;
};

export const getManager = (): OptionalSessionManager => {
  return manager;
};

export const getActiveManager = (): TokenSessionManager => {
  if (!manager) {
    throw new Error('SessionManager has not been set');
  }

  return manager;
};

export const setupManager = (db?: Storage | null): TokenSessionManager => {
  const store = db || new WindowLocalStorage(getAvailableStorage());
  const tokenClient = new TokenSessionManager(import.meta.env.VITE_API_URL, store);

  setManager(tokenClient);

  return tokenClient;
};
