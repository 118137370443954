import { useRouteError } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '@cmp/theme';
import { ErrorPage } from '@cmp/errorPage';

export interface ErrorPresenterProps {}

export const ErrorPresenter: React.FC<ErrorPresenterProps> = () => {
  const error = useRouteError();

  return (
    <ChakraProvider theme={theme}>
      <ErrorPage
        title={'Error'}
        description={'Perdón, estamos teniendo algunos inconvenientes.'}
        summary={
          error && typeof error === 'object' && 'message' in error && typeof error.message === 'string'
            ? error.message
            : 'Estamos presentando problemas en nuestra aplicación. ' +
              'Intenta más tarde nuevamente o comunícate con el área de soporte.'
        }
        backButton={{ text: 'Ir al Inicio' }}
      />
    </ChakraProvider>
  );
};
