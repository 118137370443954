import { event } from '@lib/events';
import { handleAuthenticationResult, getUserFromToken } from '@/lib/user';
import { redirect, LoaderFunction } from 'react-router-dom';

const authErrorMessage =
  'No fue posible realizar el proceso de autenticación.' +
  ' Dirígete al inicio e intenta iniciar sesión nuevamente. ' +
  'Si el problema continúa, comunícate con nuestro equipo de soporte.';

export const loader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const search = url.searchParams;
  const createTokensUrl = search.get('create_tokens');

  if (!createTokensUrl) {
    throw new Error("The given URL doesn't have a valid create_tokens parameter");
  }

  const response = await fetch(createTokensUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });

  if (!response.ok) {
    console.error('[AUTH] Got an invalid response from serve while creating the user tokens', response);

    throw new Error(authErrorMessage);
  }

  dispatchAuthEvent(search);

  const data = await response.json();
  console.log(`[AUTH] Setting the authentication tokens`, Object.keys(data));

  const user = getUserFromToken(data.access.token);
  if (!user) {
    console.error('[AUTH] The incoming token is not valid, got an empty user', data.access.token);

    throw new Error(authErrorMessage);
  }

  const redirectTo = handleAuthenticationResult({
    user: {
      ...user,
      // The variables in the JWT has different names
      // setting this values, we normalize what we need.
      emailVerified: !!user.verifiedAt,
      profileUpdated: !!user.profileUpdatedAt,
    },
    tokens: {
      access: data.access,
      refresh: data.refresh,
    },
  });

  return redirect(redirectTo);
};

/**
 * Dispatch the event related to the authentication process in the
 * analytics data layer.
 */
function dispatchAuthEvent(search: URLSearchParams): void {
  const hasActionDetail = search.has('new_user');
  if (hasActionDetail) {
    const action = search.get('new_user') === '1' ? 'register' : 'login';
    const provider = search.get('provider') || 'empty';
    event({ name: `${action}-${provider}` });
  }
}
