import * as React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export interface Img {
  format: string;
  src: string;
  width: number;
  height: number;
}

export interface ImageConfig {
  image: Img[];
  max?: number;
}

export interface SourceSetProps extends BoxProps {
  defaultCase: Img;
  alt: string;
  lazy?: boolean;
  eager?: boolean;
  imgs: ImageConfig[];
  _img?: React.HTMLAttributes<HTMLImageElement>;
}

export function SourceSet({ imgs, lazy = true, eager = false, defaultCase, _img, alt, ...props }: SourceSetProps) {
  const sets = React.useMemo(() => buildNodesList(imgs), [imgs]);

  if (eager) {
    lazy = false;
  }

  return (
    <Box as={'picture'} {...props}>
      {sets}
      <img
        alt={alt}
        // Need to set those properties to know lazy load the images.
        width={defaultCase.width}
        height={defaultCase.height}
        loading={lazy ? 'lazy' : 'eager'}
        src={typeof defaultCase === 'object' ? defaultCase.src : defaultCase}
        {..._img}
        // TODO Should this be complemented with the lazy prop?
        // decoding="async"
      />
    </Box>
  );
}

interface ImageMapByFormat {
  max?: number;
  formats: {
    [key: string]: Img[];
  };
}

const buildNodesList = (imgs: ImageConfig[]) => {
  let max = 0;
  let min = 0;
  const sets: React.ReactNode[] = [];

  // Iterate over all the sets in the list.
  for (let i = 0; i < imgs.length; i++) {
    const formatSet: ImageMapByFormat = {
      max: imgs[i].max,
      formats: {},
    };

    // Set the next minimum width based on the last maximum.
    if (max > 0) {
      min = max + 1;
    }

    // Remove the maximum values if there is no limit for this set.
    const newMax = imgs[i].max;
    if (newMax) {
      max = newMax;
    } else {
      max = 0;
    }

    // Build a map list of items for each format:
    // avif: 480px, 720px
    // webp: 480px, 720px
    for (let k = 0; k < imgs[i].image.length; k++) {
      const format = imgs[i].image[k].format;
      if (!formatSet.formats[format]) {
        formatSet.formats[format] = [];
      }
      formatSet.formats[format].push(imgs[i].image[k]);
    }

    // Push all the items to the result set.
    Object.values(formatSet.formats).forEach((format, index) => {
      const sourceProps: Record<string, string> = {
        type: `image/${format[0].format}`,
        srcSet: format.length > 1 ? format.map((image) => `${image.src} ${image.width}w`).join(', ') : format[0].src,
      };

      if (min || max) {
        sourceProps.media =
          `${min > 0 ? `(min-width: ${min}px)` : ''}` +
          `${min > 0 && max > 0 ? ` and ` : ''}` +
          `${max > 0 ? `(max-width: ${max}px)` : ''}`;
      }

      // key=avif-1-0
      sets.push(<source key={`${format[0].format}-${index}-${i}`} {...sourceProps} />);
    });
  }

  return sets;
};
