import { getActiveManager } from '@lib/session';
import { redirect, LoaderFunction } from 'react-router-dom';
import { deleteDevice } from '@hks/web-push';
import { getClient } from '@lib/apollo';

export const loader: LoaderFunction = async () => {
  const manager = getActiveManager();
  const authenticated = await manager.authenticated();

  console.log(`[AUTH] Removing the authentication tokens`, { authenticated });

  if (!authenticated) {
    return redirect('/');
  }

  try {
    // Need to wait for the deleteDevice operation to complete
    // to log the user out.
    await safeDeleteDevice();
    await logout();
  } catch (e) {
    // TODO Report error
    console.error('[TODO] Should report this error...', e);
  }

  return redirect('/');
};

async function logout() {
  const manager = getActiveManager();
  const client = getClient();

  try {
    await manager.logout();
  } finally {
    await client?.cache.reset();
  }
}

async function safeDeleteDevice() {
  try {
    await deleteDevice(getClient()!);
  } catch (e) {
    // TODO Report error
    console.error('[TODO] Should report this error...', e);
  }
}
