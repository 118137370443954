import { redirect } from 'react-router-dom';
import { getActiveManager } from '@lib/session';
import { getUserFromToken } from '@/lib/user';

export async function authenticated() {
  const manager = getActiveManager();
  const authenticated = await manager.authenticated();

  console.log('[LOADER] "authenticated" loader', { authenticated });

  if (!authenticated) {
    console.log(
      '[LOADER] Trying to use a protect route but the current session is empty.',
      'Redirecting to the sign-in page',
    );
    return redirect('/');
  }

  return null;
}

export async function accountValidated() {
  const result = await authenticated();
  if (result) {
    return result;
  }

  const user = getCurrentUser();
  if (!user.verifiedAt) {
    console.log(
      '[LOADER] Trying to use a protect route but the current session is not valid.',
      'Redirecting to the /verificacion page',
    );
    return redirect('/verificacion');
  }

  return null;
}

export async function profileUpdated() {
  const result = await accountValidated();
  if (result) {
    return result;
  }

  const user = getCurrentUser();
  if (!user.profileUpdatedAt) {
    console.log(
      '[LOADER] Trying to use a protect route but the current session is not valid.',
      'Redirecting to the /datos-personales page',
    );
    return redirect('/datos-personales');
  }

  return null;
}

function getCurrentUser() {
  const manager = getActiveManager();
  const user = getUserFromToken(manager.accessToken!.token);
  if (!user) {
    console.error('[GET_CURRENT_USER] Failed to decode the JWT', manager.accessToken!.token);

    throw new Error(
      'Las credenciales de autenticación almacenadas no son válidas. ' +
        'Intenta iniciar sesión nuevamente, si el problema persiste contacta al ' +
        'equipo de soporte',
    );
  }

  return user;
}

export async function authenticatedAdmin() {
  const result = await authenticated();
  if (result) {
    return result;
  }

  const user = getCurrentUser();
  if (user.role !== 'admin') {
    console.error('[AUTH] The authenticated user is not an admin', user);

    throw new Error('El usuario no cuenta con un rol válido');
  }

  return null;
}
