import { App } from './App.tsx';
import { createBrowserRouter } from 'react-router-dom';
import { ErrorPresenter } from '@cmp/errorPresenter';
import guestLoader from '@/loaders/guest';
import { processQrCodeInQuery, processQrCodeInPath, hasPendingQrCodes } from '@/loaders/processQrCode';
import { accountValidated, profileUpdated } from '@/loaders/protected';
import { ifNoPendingQrCode } from '@/loaders/processQrCode.ts';
import { loader as createTokenLoader } from '@lnd/loaders/create-tokens';
import { loader as signOut } from '@/loaders/signout';
import { EXCHANGE_CODES, EXCHANGE_FROM_CODE_IN_PATH } from './routes';

export const createRouter = () => {
  return createBrowserRouter([
    {
      path: '/',
      element: <App />,
      errorElement: <ErrorPresenter />,
      children: [
        {
          index: true,
          path: '/',
          lazy: async () => {
            const Component = (await import('@lnd/pages/home/landing')).default;
            return { Component };
          },
        },
        {
          path: '/ganadores',
          lazy: async () => {
            const Component = (await import('@lnd/pages/home/winners')).default;
            return { Component };
          },
        },
        {
          path: '/estaciones-participantes',
          lazy: async () => {
            const Component = (await import('@lnd/pages/home/estaciones-participantes')).default;
            return { Component };
          },
        },
        {
          path: '/faq',
          lazy: async () => {
            const Component = (await import('@lnd/pages/home/FAQ')).default;
            return { Component };
          },
        },
        {
          path: '/iniciar-sesion',
          loader: guestLoader,
          lazy: async () => {
            const Component = (await import('@lnd/pages/validacionIdentidad/iniciar-sesion')).default;
            return { Component };
          },
        },
        {
          path: '/registro',
          loader: guestLoader,
          lazy: async () => {
            const Component = (await import('@lnd/pages/validacionIdentidad/registro')).default;

            return { Component };
          },
        },

        {
          path: '/configurar-perfil',
          // loader: guestLoader,
          lazy: async () => {
            const Component = (await import('@lnd/pages/home/configurar-perfil')).default;

            return { Component };
          },
        },

        {
          path: '/reiniciar-contrasena',
          loader: guestLoader,
          lazy: async () => {
            const Component = (await import('@lnd/pages/validacionIdentidad/reiniciar-contrasena')).default;
            return { Component };
          },
        },

        {
          path: '/restablecer-contrasena',
          loader: guestLoader,
          lazy: async () => {
            const Component = (await import('@lnd/pages/validacionIdentidad/reenviar-contrasena.tsx')).default;
            return { Component };
          },
        },

        {
          path: '/authentication/create-tokens',
          loader: createTokenLoader,
        },

        {
          path: '/cerrar-sesion',
          loader: signOut,
        },

        {
          path: '/authentication/reset-password',
          loader: guestLoader,
          lazy: async () => {
            const Component = (await import('@lnd/pages/validacionIdentidad/reiniciar-contrasena')).default;
            return { Component };
          },
        },
        {
          path: '/home',
          loader: ifNoPendingQrCode(profileUpdated),
          lazy: async () => {
            const Component = (await import('@lnd/pages/home/home')).default;
            return { Component };
          },
        },

        {
          path: EXCHANGE_CODES,
          loader: profileUpdated,
          lazy: async () => {
            const Component = (await import('@lnd/pages/home/codes-register.tsx')).default;
            return { Component };
          },
        },

        {
          path: '/data-voucher',
          loader: profileUpdated,
          lazy: async () => {
            const Component = (await import('@lnd/pages/home/data-voucher')).default;
            return { Component };
          },
        },

        {
          path: '/datos-personales',
          loader: accountValidated,
          lazy: async () => {
            const Component = (await import('@lnd/pages/home/datos-personales')).default;
            return { Component };
          },
        },

        {
          path: '/perfil',
          loader: profileUpdated,
          lazy: async () => {
            const Component = (await import('@lnd/pages/home/perfil')).default;
            return { Component };
          },
        },

        {
          path: '/escanear-qr',
          loader: profileUpdated,
          lazy: async () => {
            const Component = (await import('@lnd/pages/home/escanear-qr')).default;
            return { Component };
          },
        },

        {
          path: '/ganador-sorteo',
          loader: profileUpdated,
          lazy: async () => {
            const Component = (await import('@lnd/pages/home/palanca-de-cambios')).default;
            return { Component };
          },
        },

        {
          path: '/qr-canjeado',
          // All the user can scan QR codes.
          // Those values will be stored and sent
          // to the server later.
          lazy: async () => {
            const Component = (await import('@lnd/pages/home/qr-canjeado')).default;
            return { Component };
          },
        },

        {
          path: '/qr',
          loader: processQrCodeInQuery,
        },

        {
          path: `${EXCHANGE_FROM_CODE_IN_PATH}/:code`,
          loader: processQrCodeInPath,
        },

        {
          // This route takes the pending QR
          // code and exchanges it.
          path: '/procesar-qr',
          loader: hasPendingQrCodes,
          lazy: async () => {
            const Component = (await import('@lnd/pages/home/procesar-qr')).default;
            return { Component };
          },
        },

        {
          path: '/auth/callback',
          lazy: async () => {
            const Component = await import('@lnd/pages/validacionIdentidad/auth-callback');
            return { Component: Component.default };
          },
        },

        {
          path: '/error',
          lazy: async () => {
            const Component = await import('@lnd/pages/error');
            return { Component: Component.default };
          },
        },

        {
          path: '/politicas-de-privacidad-plain-text',
          lazy: () => import('@lnd/pages/privacy-policies'),
        },

        {
          path: '*',
          lazy: async () => {
            const Component = (await import('@lnd/pages/other/not-found')).default;
            return { Component };
          },
        },
      ],
    },
  ]);
};
