import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { CREATE_DEVICE, DELETE_DEVICE } from '@/gql/devices';
import { CreateDeviceMutation } from '@/gql/__generated__/graphql';
import { TokenSessionManager } from '@lib/http';
import { getAvailableStorage } from '@lib/storage';

/**
 * Update the token with every permission change and deletes the device token
 * if the user is logged out.
 */
export const addListeners = async (manager: TokenSessionManager, client: ApolloClient<NormalizedCacheObject>) => {
  watchForGranted(manager, client);
  watchForSignIn(manager, client);
  watchForAuthChangeToNotifySW(manager);
};

const writeDevice = (device: CreateDeviceMutation['createDevice']) => {
  getAvailableStorage().setItem('DEVICE', JSON.stringify(device));
};

const removeDeviceFromLocalStorage = () => {
  getAvailableStorage().setItem('DEVICE', JSON.stringify(null));
};

const readDevice = (): CreateDeviceMutation['createDevice'] | null => {
  const stored = getAvailableStorage().getItem('DEVICE');
  if (!stored) {
    return null;
  }

  const device = JSON.parse(stored);
  if (!device) {
    return null;
  }

  return device;
};

export const deleteDevice = async (client: ApolloClient<NormalizedCacheObject>) => {
  const device = readDevice();
  if (!device) {
    return;
  }

  try {
    await client.mutate({
      mutation: DELETE_DEVICE,
      variables: { input: { id: device.id } },
    });
  } finally {
    removeDeviceFromLocalStorage();
  }
};

const registerDevice = async (client: ApolloClient<NormalizedCacheObject>) => {
  const registration = await navigator.serviceWorker.ready;
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(import.meta.env.VITE_WEB_PUSH_PUBLIC_KEY),
  });

  const values = JSON.parse(JSON.stringify(subscription));
  const p256dh = values.keys.p256dh;
  const auth = values.keys.auth;

  if (!p256dh || !auth) {
    console.log('[SUBSCRIPTION] Empty subscription values', { p256dh, auth });
    throw new Error('Empty subscription values. No Public Key was provided');
  }

  const response = await client.mutate({
    mutation: CREATE_DEVICE,
    variables: {
      input: {
        type: 'browser',
        url: subscription.endpoint,
        keys: { p256dh, auth },
      },
    },
  });
  writeDevice(response.data?.createDevice);
};

const ifAuthenticated = async (manager: TokenSessionManager, cb: () => unknown) => {
  const authenticated = await manager.authenticated();
  if (authenticated) {
    await cb();
  }
};

const supportQueryAPI = 'permissions' in navigator && 'query' in navigator.permissions;

/**
 * Register the device if the browser doesn't support the permissions.query API.
 */
export function registerIfNeeded(manager: TokenSessionManager, client: ApolloClient<NormalizedCacheObject>) {
  //The listener will handle the permission status change.
  if (supportQueryAPI) {
    return;
  }

  return ifAuthenticated(manager, () => registerDevice(client));
}

const watchForGranted = async (manager: TokenSessionManager, client: ApolloClient<NormalizedCacheObject>) => {
  if (!supportQueryAPI) {
    return;
  }

  const permissionStatus = await navigator.permissions?.query({ name: 'notifications' });
  console.log('[NOTIFICATIONS] Current status', { permissionStatus });

  // If the current status is 'granted' and the device in the localStorage
  // is empty. A new device is created in the server.
  const storedDevice = readDevice();
  if (!storedDevice && permissionStatus.state === 'granted') {
    ifAuthenticated(manager, () => registerDevice(client));
  }

  permissionStatus.addEventListener('change', async () => {
    console.log('[NOTIFICATIONS] Status changed', { permissionStatus });
    if (permissionStatus?.state === 'granted') {
      ifAuthenticated(manager, () => registerDevice(client));
      return;
    }

    await ifAuthenticated(manager, () => deleteDevice(client));
    // Sometimes the device already exists but the session has
    // expired. Anyways, we will remove the device from the LS
    removeDeviceFromLocalStorage();
  });
};

const watchForSignIn = async (manager: TokenSessionManager, client: ApolloClient<NormalizedCacheObject>) => {
  manager.onChange((tokens) => {
    if (!tokens) {
      return;
    }

    const storedDevice = readDevice();
    if (!storedDevice && 'Notification' in window && Notification.permission === 'granted') {
      registerDevice(client);
    }
  });
};

const watchForAuthChangeToNotifySW = async (manager: TokenSessionManager) => {
  manager.onChange(async (tokens) => {
    // Doesn't support web push notifications.
    if (!('serviceWorker' in navigator)) {
      return;
    }

    // Notify the SW about the change in the user session.
    navigator.serviceWorker.ready.then((reg) => {
      reg.active?.postMessage({ action: tokens ? 'logged-in' : 'logout' });
    });
  });
};

// Public base64 to Uint
function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
