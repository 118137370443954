import { ApolloClient, from, HttpLink, InMemoryCache, ServerError, NormalizedCacheObject, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { getActiveManager } from '@lib/session';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';

interface LocalApolloClientOptions {
  fetch: WindowOrWorkerGlobalScope['fetch'];
  language?: string;
  ssrMode?: boolean;
}

let client: ApolloClient<NormalizedCacheObject> | null = null;

export function getClient() {
  return client;
}

export function setClient(newClient: ApolloClient<NormalizedCacheObject>) {
  client = newClient;
}

export const newApolloClient = ({ fetch }: LocalApolloClientOptions): ApolloClient<NormalizedCacheObject> => {
  const httpLinkInstance = httpLink(fetch);

  const wsLink = new GraphQLWsLink(
    createClient({
      url: import.meta.env.VITE_WS_URL || 'ws://localhost:3000/subscriptions',
      connectionParams: () => {
        return { authToken: getActiveManager().tokens()[0]?.token };
      },
    }),
  );

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLinkInstance,
  );

  return new ApolloClient({
    link: createPersistedQueryLink({ sha256 }).concat(link),
    cache: new InMemoryCache(),
  });
};

const retryLink = () => {
  return new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error) => !!error && error.statusCode !== 419,
    },
  });
};

const httpLink = (newFetch: WindowOrWorkerGlobalScope['fetch']) => {
  const link = new HttpLink({
    uri: import.meta.env.VITE_API_URL,
    fetch: newFetch,
  });

  return from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.error(`[GraphQL error] Message: ${message}, Location: ${locations}, Path: ${path}`),
        );
      }

      if (networkError) {
        const { statusCode } = networkError as ServerError;

        console.error(`[Network error] ${networkError}`, `Status code: ${statusCode}`);
      }
    }),
    retryLink(),
    link,
  ]);
};
