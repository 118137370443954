/* eslint-disable @typescript-eslint/no-explicit-any */

export class WindowLocalStorage {
  constructor(private storage: Storage) {}

  async getItem<T = unknown>(name: string): Promise<T | undefined> {
    const response = this.storage.getItem(name);
    const value = response ? JSON.parse(response) : undefined;
    console.log('[STORAGE] Getting key', name, response, value);
    return value;
  }

  async setItem(name: string, value: unknown): Promise<void> {
    this.storage.setItem(name, JSON.stringify(value));
  }

  async removeItem(name: string): Promise<void> {
    this.storage.removeItem(name);
  }
}

export class InMemoryStorage {
  constructor(private store: { [key: string]: any } = {}) {}

  getItem(name: string): string | null {
    if (name in this.store) {
      return this.store[name];
    }

    return null;
  }

  setItem(name: string, value: unknown): void {
    this.store[name] = value;
  }

  removeItem(name: string): void {
    delete this.store[name];
  }

  get length(): number {
    return Object.keys(this.store).length;
  }

  clear(): void {
    this.store = {};
  }

  key(index: number): string | null {
    const keys = Object.keys(this.store);
    if (index >= keys.length) {
      return null;
    }

    return this.getItem(keys[index]);
  }
}

function checkIsSupported(storage: Storage) {
  try {
    const testKey = '__UNUSED:KEY__' + Math.random().toString();
    storage.setItem(testKey, testKey);
    const got = storage.getItem(testKey);
    storage.removeItem(testKey);
    return got === testKey;
  } catch (e) {
    console.error('[STORAGE] setItem/getItem', e);
    return false;
  }
}

let inMemoryStorage = new InMemoryStorage({});

export function getAvailableStorage() {
  try {
    if ('localStorage' in window && checkIsSupported(window.localStorage)) {
      return window.localStorage;
    }
  } catch (e) {
    console.log('[STORAGE] Local storage is not supported');
  }

  try {
    if ('sessionStorage' in window && checkIsSupported(window.sessionStorage)) {
      return window.sessionStorage;
    }
  } catch (e) {
    console.log('[STORAGE] Session storage is not supported');
  }

  // Storage was garbage collected.
  if (!inMemoryStorage) {
    inMemoryStorage = new InMemoryStorage({});
  }

  return inMemoryStorage;
}

/* eslint-enable @typescript-eslint/no-explicit-any */
