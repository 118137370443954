export function register() {
  if (!('serviceWorker' in navigator)) {
    console.log('[SW] Service Worker not supported');
    return;
  }

  window.addEventListener('load', async () => {
    const registration = await navigator.serviceWorker.register('/sw.iife.js');
    console.log('Service Worker registrado con scope:', registration.scope);
  });
}
