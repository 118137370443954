import * as React from 'react';
import { UserContext, getUserFromToken } from './user';
import { User } from '@lib/user';
import { useSessionManager } from '@hks/session';

export interface UseProviderProps {
  children: React.ReactNode;
}

export const UserProvider: React.FC<UseProviderProps> = (props) => {
  const [loading, setLoading] = React.useState(true);
  const manager = useSessionManager();

  const [user, setUser] = React.useState<User | null>(null);

  const decodeAndSet = React.useCallback(
    function setUserFromToken() {
      const tokens = manager?.tokens();
      if (!tokens || tokens.length === 0 || !tokens[0]?.token) {
        setUser(null);
        return;
      }

      setUser(getUserFromToken(tokens[0]?.token));
    },
    [setUser, manager],
  );

  React.useEffect(
    function fetchAuthenticatedUser() {
      if (!manager) {
        console.warn('[UserProvider] The session manager is not set');
        return;
      }

      manager
        .authenticated()
        .then((authenticated) => {
          console.log('[UserProvider] User is authenticated', { authenticated });
          if (!authenticated) {
            return;
          }

          decodeAndSet();
        })
        .finally(() => setLoading(false));
    },
    [manager, decodeAndSet],
  );

  React.useEffect(
    function watchForChanges() {
      if (!manager) {
        console.warn('[UserProvider] The session manager is not set');
        return;
      }

      const callbacks: (() => unknown)[] = [];

      callbacks.push(
        manager.onChange(async (tokens) => {
          console.log('[UserProvider] changed', { tokens });

          // Fetch the user profile again.
          if (tokens) {
            decodeAndSet();
            return;
          }

          // Clear the apollo cache and stop the loading step
          console.log('[UserProvider] User not authenticated, will remove the user from the context');
          setUser(null);
        }),
      );

      return () => callbacks.forEach((cb) => cb());
    },
    [manager, decodeAndSet],
  );

  return (
    <UserContext.Provider
      value={React.useMemo(
        () => ({
          user,
          loading,
        }),
        [user, loading],
      )}
    >
      {props.children}
    </UserContext.Provider>
  );
};
