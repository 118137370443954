import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { SessionProvider } from '@cmp/session';
import { getActiveManager } from '@lib/session';
import { getClient } from '@lib/apollo';

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  return (
    <ApolloProvider client={getClient()!}>
      <SessionProvider manager={getActiveManager()}>{children}</SessionProvider>
    </ApolloProvider>
  );
};
