import * as React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { SourceSet } from '@cmp/SourceSet';
import './loader.css';

// @ts-expect-error Typescript doesn't know about the image plugin.
import Bg from '../img/newlogo.png?w=200;300&format=avif;webp&as=metadata';
// @ts-expect-error Typescript doesn't know about the image plugin.
import logoPrimax from '../img/newlogo.png?w=200&format=png';

export interface LoaderProps extends BoxProps {
  hasAnimation?: boolean;
}

const Loader: React.FC<LoaderProps> = ({ children, hasAnimation = true, ...props }) => {
  return (
    <Box
      mt="-64px"
      overflow={'hidden'}
      textAlign="center"
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      w="100%"
      h="100vh"
      position={'relative'}
      zIndex={'2000'}
      bg="white"
      {...props}
    >
      {children}
      <SourceSet
        width={'200px'}
        defaultCase={logoPrimax}
        imgs={[{ image: Bg }]}
        alt={'Animación de carga'}
        className={hasAnimation ? 'logo-animation' : ''}
      />
    </Box>
  );
};

export default Loader;
