import { joinPaths } from './path';
import { Queue } from '../lib/queue';
import { CSRFError, InternalServerError, RequestError, HttpError } from './errors';

const ACCESS_TOKEN_KEY = 'session:accesstoken';

const REFRESH_TOKEN_KEY = 'session:refreshtoken';

/* eslint-disable @typescript-eslint/no-explicit-any */

export type ServerResponse =
  | { data?: any; errors: { message: string; extensions?: Record<string, string> }[] }
  | undefined;

export interface AccessToken {
  type: string;
  token: string;
  expiresIn: number;
  now: number;
}

export interface RefreshToken {
  token: string;
  expiresIn: number;
  now: number;
}

export interface Storage {
  getItem: (name: string) => Promise<any | undefined>;
  setItem: (name: string, value: any) => Promise<void>;
  removeItem: (name: string) => Promise<void>;
}

export type ChangeListener = (
  ac: {
    access: AccessToken;
    refresh: RefreshToken;
  } | null,
) => unknown;

export type Listener = () => unknown;

export class TokenSessionManager {
  public accessToken?: AccessToken | null;
  private refreshToken?: RefreshToken | null;
  private queue: Queue;
  private onChangeCallbacks: ChangeListener[] = [];
  private onBeforeLogoutCallback: Listener[] = [];
  private fetchFunction: WindowOrWorkerGlobalScope['fetch'];

  constructor(
    private uri: string,
    private storage: Storage,
    fetchFunction?: WindowOrWorkerGlobalScope['fetch'],
  ) {
    this.queue = new Queue([]);
    this.fetchFunction = fetchFunction || window.fetch.bind(window);
  }

  public listenForChanges() {
    window.addEventListener('storage', (e) => {
      if (e.key === null) {
        console.log('[STORAGE] Cleared');
        return;
      }

      if ([ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY].indexOf(e.key) < 0) {
        return;
      }

      console.log('[STORAGE] token updated', {
        oldValue: !!e.oldValue,
        newValue: !!e.newValue,
      });
    });
  }

  public async loadToken() {
    const [accessToken, refreshToken] = await Promise.all([this.read(ACCESS_TOKEN_KEY), this.read(REFRESH_TOKEN_KEY)]);

    console.log('[AUTH] Loading tokens', {
      accessToken: !!this.accessToken,
      refreshToken: !!this.refreshToken,
      newAccessToken: !!accessToken,
      newRefreshToken: !!refreshToken,
    });

    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }

  async logout() {
    if (!this.refreshToken) {
      throw new Error('Could not "logout", the access token is not valid');
    }

    // We don't want to refresh the access token in this request.
    this.removeAccessToken();

    this.runBeforeLogout();

    try {
      await this.fetch(this.prepareURL(), {
        method: 'POST',
        body: JSON.stringify({
          query: 'mutation logout($input: LogoutInput!) { logout(input: $input) }',
          operationName: 'logout',
          variables: {
            input: { refreshToken: this.refreshToken.token },
          },
        }),
      });
    } finally {
      this.removeTokens();
      this.runOnChange();
    }

    return;
  }

  public onChange(listener: ChangeListener): () => void {
    this.onChangeCallbacks.push(listener);

    return () => {
      this.onChangeCallbacks = this.onChangeCallbacks.filter((cb) => cb !== listener);
    };
  }

  public onBeforeLogout(listener: Listener): () => void {
    this.onBeforeLogoutCallback.push(listener);

    return () => {
      this.onBeforeLogoutCallback = this.onBeforeLogoutCallback.filter((cb) => cb !== listener);
    };
  }

  private runOnChange() {
    this.onChangeCallbacks.forEach((cb) => {
      const access = this.accessToken;
      const refresh = this.refreshToken;

      cb(access && refresh ? { access, refresh } : null);
    });
  }

  private runBeforeLogout() {
    this.onBeforeLogoutCallback.forEach((cb) => cb());
  }

  forgotPassword(email: string, token?: string | null): Promise<ServerResponse> {
    return this.serverFetch(this.prepareURL(), {
      method: 'POST',
      body: JSON.stringify({ email, captcha_token: token }),
    });
  }

  resetPassword(email: string, token: string, password: string): Promise<ServerResponse> {
    return this.serverFetch(this.prepareURL(), {
      method: 'POST',
      body: JSON.stringify({ email, token, password }),
    });
  }

  async signIn(values: { email: string; password: string }): Promise<ServerResponse> {
    this.removeTokens();

    const response = await this.serverFetch(this.prepareURL(), {
      method: 'POST',
      body: JSON.stringify({
        query: `mutation login($input: LoginInput!) {
          login(input: $input) {
            user {
              ...UserProfile
            }
            tokens {
              ...AllAccessTokens
            }
          }
        } ${this.accessTokensFragment()} ${this.userFragment()}`,
        operationName: 'login',
        variables: { input: values },
      }),
    });

    const access = response?.data?.login?.tokens;
    if (access?.accessToken) {
      this.loginWithTokens(access.accessToken, access.refreshToken);
      return;
    }

    return response;
  }

  async serverFetch(input: RequestInfo, init?: RequestInit): Promise<ServerResponse> {
    const response = await this.fetch(input, init);

    return this.handleResponse(response);
  }

  public tokens() {
    return [this.accessToken, this.refreshToken];
  }

  public hasSession(): boolean {
    return !!this.accessToken || !!this.refreshToken;
  }

  public async authenticated() {
    console.debug('[AUTH] Checking if the user is authenticated');
    // Prevent that the authentication process is checked
    // while the tokens are not available.
    return this.queue.pushAsync(async () => {
      const hasSession = this.hasSession();
      const activeSession = this.activeSession();
      console.log('[AUTH] authenticated?', {
        hasSession,
        activeSession,
        hasAccessToken: !!this.accessToken,
        hasRefreshToken: !!this.refreshToken,
      });

      if (!hasSession) {
        return false;
      }

      if (!activeSession) {
        await this.refreshSession();
      }

      return this.hasSession();
    });
  }

  public refreshSession(force: boolean = false) {
    return this.queue.pushSync(async () => {
      const refreshTokenHasExpired = this.refreshTokenHasExpired();

      if (refreshTokenHasExpired) {
        console.log('[AUTH] Removing the tokens. Found that the Refresh token has expired before making the request');
        this.removeTokens();
        return;
      }

      const shouldRefresh = this.jwtHasExpired();
      console.log('[AUTH] Should refresh JWT', {
        shouldRefresh,
        force,
        hasAccessToken: !!this.accessToken,
        hasRefreshToken: !!this.refreshToken,
      });
      if (!shouldRefresh && !force) {
        return;
      }

      await this.refreshJWT();

      // User properties can be updated.
      this.runOnChange();
    });
  }

  public activeSession(): boolean {
    return !!this.accessToken && !this.refreshTokenHasExpired();
  }

  async validateAccount(code: string): Promise<void> {
    const response = await this.serverFetch(this.prepareURL(), {
      method: 'POST',
      body: JSON.stringify({
        query: `mutation validateUserAccount($input: ValidateUserAccountInput!) {
          validateUserAccount(input: $input) {
            user { id validated }
          }
        }`,
        operationName: 'validateUserAccount',
        variables: { input: { code } },
      }),
    });

    if (!response) {
      throw new Error('Empty respose from the server');
    }

    const { data, errors } = response;

    if (errors) {
      throw new RequestError('Invalid response from the server', errors as any);
    }

    if (!data?.validateUserAccount) {
      throw new Error('Got an invalid response form the server: ' + JSON.stringify(data));
    }
  }

  async sendValidationCode(): Promise<void> {
    const response = await this.serverFetch(this.prepareURL(), {
      method: 'POST',
      body: JSON.stringify({
        query: `mutation sendValidationCodeToUserAccount {
          sendValidationCodeToUserAccount {
            user {
              id
              validated
            }
          }
        }`,
        operationName: 'sendValidationCodeToUserAccount',
        variables: {},
      }),
    });

    if (!response) {
      throw new Error('Empty respose from the server');
    }

    const { data, errors } = response;

    if (errors) {
      throw new RequestError('Invalid response from the server', errors as any);
    }

    if (!data?.sendValidationCodeToUserAccount) {
      throw new Error('Got an invalid response form the server: ' + JSON.stringify(data));
    }
  }

  public async loginWithTokens(
    accessToken: Omit<AccessToken, 'now'>,
    refreshToken: Omit<RefreshToken, 'now'>,
  ): Promise<void> {
    await this.setToken(accessToken, refreshToken);
    this.runOnChange();
  }

  /**
   * Add a middleware to check the CSRF Token.
   */
  async fetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
    const headers: Record<string, string> = {
      'content-type': 'application/json',
      accept: 'application/json',
    };

    const isRefresing =
      typeof init === 'object' &&
      init.body &&
      typeof init.body === 'string' &&
      init.body.match(/"operationName":"refreshToken"/);

    if (!isRefresing) {
      // await this.loadToken();
      await this.refreshSession();
    }

    const callback = async () => {
      if (this.accessToken && !isRefresing) {
        headers['Authorization'] = `${this.accessToken.type} ${this.accessToken.token}`;
      }

      const response = await this.fetchFunction(input, {
        ...init,
        headers,
        credentials: 'include',
      });

      // The JWT is not longer valid.
      if (response.status === 498) {
        console.log('[AUTH] Refreshing the tokens. Server said it is needed');
        await this.refreshJWT();

        return await this.fetch(input, init);
      }

      // The refresh token has expired.
      if (response.status === 440) {
        console.log('[AUTH] Removing the tokens. Server said that the refresh token has expired or is invalid');

        this.removeTokens();
      }

      return response;
    };

    if (isRefresing) {
      return callback();
    }

    return this.queue.pushAsync(callback);
  }

  private refreshTokenHasExpired(): boolean {
    if (!this.refreshToken) {
      return false;
    }

    const expiresAt = (this.refreshToken.now || 0) + this.refreshToken.expiresIn * 1000;
    const now = new Date().getTime();

    return now > expiresAt;
  }

  private jwtHasExpired(): boolean {
    if (!this.accessToken) {
      return !!this.refreshToken;
    }

    // Getting only positive values allow us to test token that
    // has expired too much time before
    const leeway = Math.abs(this.accessToken.expiresIn * 1000 * 0.1);
    const expiresAt = (this.accessToken.now || 0) + this.accessToken.expiresIn * 1000 - leeway;
    const now = new Date().getTime();
    const expired = now > expiresAt;

    if (expired) {
      console.log('[AUTH] Expired jwt', {
        now,
        expiresAt,
        leeway,
        accessTokenNow: this.accessToken.now,
        accessTokenExpiredIn: this.accessToken.expiresIn,
      });
    }

    return expired;
  }

  private async refreshJWT() {
    if (!this.refreshToken) {
      throw new Error('Could not refresh the JWT, refresh token is empty');
    }

    console.log('[AUTH] Refreshing access token');

    // Won't remove the access token.
    // this.removeAccessToken();

    try {
      const response = await this.serverFetch(this.prepareURL(), {
        method: 'POST',
        body: JSON.stringify({
          query: `mutation refreshToken($input: RefreshTokenInput!) {
              refreshToken(input: $input) {
                ...AllAccessTokens
              }
            } ${this.accessTokensFragment()}`,

          operationName: 'refreshToken',
          variables: {
            input: { token: this.refreshToken.token },
          },
        }),
      });

      const access = response?.data?.refreshToken;
      if (access) {
        await this.setToken(access.access, access.refresh);
        return response;
      }

      if (response?.errors?.[0]?.extensions?.code === 'INVALID_TOKEN') {
        console.warn('[AUTH] Removing the tokens as the server return INVALID_TOKEN');
        this.removeTokens();
        window.location.reload();
        return;
      }

      console.warn('[AUTH] Tried to get new tokens but got an empty response', response);
      throw new Error('Tried to get new tokens but got an empty response');
    } catch (e) {
      // This happens only when the refresh token is invalid.
      if (e instanceof HttpError && e.response.status === 400) {
        console.warn('[AUTH] Removing the tokens as the server response was 400');

        this.removeTokens();
        // Should reload the current page. Now the user doesn't
        // have any valid token.
        window.location.reload();
        return;
      }

      throw e;
    }
  }

  private prepareURL(): string {
    return joinPaths(this.uri);
  }

  private async handleResponse(response: Response): Promise<ServerResponse> {
    if (response.status === 204) {
      return;
    }

    if (response.ok) {
      return response.json();
    }

    // Error in the input, or bad credentials.
    if (response.status === 422 || response.status === 429) {
      const content = await response.json();

      return content;
    }

    // Refresh token has expired.
    if (response.status === 440) {
      this.removeTokens();
    }

    if (response.status === 419) {
      throw CSRFError;
    }

    if (response.status >= 500) {
      throw new InternalServerError(response);
    }

    const content = await response.text();
    throw new HttpError(content || 'Empty response from server', response);
    // TODO If was a internal server error show a message.
    // TODO If there were to many tries show a message.
  }

  public async setToken(accessToken: Omit<AccessToken, 'now'>, refreshToken: Omit<RefreshToken, 'now'>) {
    const now = new Date().getTime();

    this.accessToken = accessToken ? { ...accessToken, now } : null;
    this.refreshToken = refreshToken ? { ...refreshToken, now } : null;

    console.log('[AUTH] Setting the auth tokens', {
      now,
      accessToken: !!accessToken?.token,
      refreshToken: !!refreshToken?.token,
    });

    await Promise.all([
      this.write(ACCESS_TOKEN_KEY, this.accessToken),
      this.write(REFRESH_TOKEN_KEY, this.refreshToken),
    ]);
  }

  public async removeTokens() {
    await Promise.all([this.removeAccessToken(), this.removeRefreshToken()]);
  }

  private async removeAccessToken() {
    console.log('[AUTH] Removing access token');

    this.accessToken = null;
    await this.storage.removeItem(ACCESS_TOKEN_KEY);
  }

  private async removeRefreshToken() {
    console.log('[AUTH] Removing refresh token');

    this.refreshToken = null;
    await this.storage.removeItem(REFRESH_TOKEN_KEY);
  }

  private async read(key: string) {
    return await this.storage.getItem(key);
  }

  private async write(key: string, value: any) {
    console.log('[AUTH] Writing token', key, !!value);

    if (!value) {
      await this.storage.removeItem(key);
      return;
    }

    await this.storage.setItem(key, value);
  }

  private accessTokensFragment() {
    return `fragment AllAccessTokens on AuthenticationTokens {
      access {
        type
        token
        expiresIn
      }
      refresh {
        token
        expiresIn
      }
    }`;
  }

  private userFragment() {
    return `fragment UserProfile on User {
      id
      name
      lastName
      email
      emailValidated
      profileUpdated
    }`;
  }
}

/* eslint-enable @typescript-eslint/no-explicit-any */
