import React from 'react';
import { TokenSessionManager } from '@lib/http';
import { SessionManagerContext } from '@hks/session';

export const SessionProvider: React.FC<{
  manager: TokenSessionManager | null;
  children: React.ReactNode;
}> = ({ manager, children }) => (
  <SessionManagerContext.Provider value={manager}>{children}</SessionManagerContext.Provider>
);
