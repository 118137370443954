import * as React from 'react';

export const WpContext = React.createContext<[string, (v: string) => void]>(['light', () => {}]);

export const useDarkTheme = () => {
  const [, setTheme] = useWpTheme();
  React.useEffect(() => {
    setTheme('dark');
    return () => setTheme('light');
  }, [setTheme]);
};

export const useWpTheme = () => {
  return React.useContext(WpContext);
};
