import { useMutation, MutationHookOptions } from '@apollo/client';
import { EXCHANGE_MUTATION } from '@/gql/oportunities';
import { ExchangeOpportunityMutation, MutationExchangeOpportunityArgs } from '@/gql/__generated__/graphql';
import { useUser } from '@lib/user';

export const useExchangeOpportunity = (
  op: MutationHookOptions<ExchangeOpportunityMutation, MutationExchangeOpportunityArgs>,
) => {
  const { user } = useUser();

  return useMutation(EXCHANGE_MUTATION, {
    /**
     * Add the new item to the list of opportunities, this will make
     * the UI more intuitive and, probably, will avoid additional
     * queries in the 'opportunities' attribute.
     */
    update(cache, result) {
      const exchangeOpportunity = result.data?.exchangeOpportunity;

      if (!exchangeOpportunity) {
        console.warn("[USE_EXCHANGE_OPPORTUNITY] Invalid data in the 'update' callback", exchangeOpportunity);
        return;
      }

      if (!user) {
        console.warn("[CACHE] Won't update the cache, the current user is empty");
        return;
      }

      cache.modify({
        id: cache.identify({ __typename: 'User', id: user.id }),
        fields: {
          opportunities(existing = {}) {
            return {
              ...existing,
              data: [exchangeOpportunity, ...existing.data],
            };
          },
        },
      });
    },
    ...op,
  });
};
