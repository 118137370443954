import { graphql } from '@/gql/__generated__';

export const CREATE_DEVICE = graphql(`
  mutation createDevice($input: CreateDeviceInput!) {
    createDevice(input: $input) {
      id
    }
  }
`);

export const DELETE_DEVICE = graphql(`
  mutation deleteDevice($input: DeleteDeviceInput!) {
    deleteDevice(input: $input) {
      id
    }
  }
`);
